:root {
    --node-GRAY: rgb(45, 49, 65);
    --node-bckgnd-color: rgb(38, 42, 56);
    --node-cmenu-color: #445a74;
}

#Create {
    position: relative;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 3.5em auto;
    height: 100%;
}

#Create header {
    position: relative;
    background-color: var(--node-GRAY);
    color: white;
    padding: .25em 10px;
    grid-column-start: 1;
    grid-column-end: 3;
    display: flex;
    justify-content: space-between;
}

#Create header > .Create-Hdr-Options {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 1em;
    padding: .2em;
}

#Create header > .Create-Hdr-Title {
    overflow: hidden;
    vertical-align: middle;
    display: flex;
    align-items: center;
}

#Create header h1 {
    margin: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

#Create > *:not(header) {
    overflow-y: scroll; 
}

#Create > *:not(header)::-webkit-scrollbar{
    display: none;
}

#Create > main {
    border-right: 8px solid var(--node-GRAY);
}

.draft-Preview {
    background-color: var(--URBAN-WHITE);
}

.draft-Preview > .dft-prvw-bttn {
    position: sticky;
    top: 0;
    bottom: 1em;

    font-size: large;
    font-weight: bold;
    padding: .7em 1.5em;
    background: rgb(8, 158, 208);
    color: white;
    border: 0;
    border-radius: 15px;
    box-shadow: 2px 2px 5px rgba(0,0,0,.2), -2px 1px 5px rgba(0,0,0,.2);
}

.draft-Preview > .dft-prvw-bttn:hover {
    cursor: pointer;
    background-color: rgb(51, 208, 239);
}

.Create-Buffer {
    height: 3em;
}

/* === */

.Create-Hdr-Btn {
    height: 100%;
    width: 8em;
    border-radius: 5px;
    font-size: 1.2em;
    border-width: 5px;
    border-style: solid;
    transition: all .1s ease-in-out;
}

.Create-Hdr-Btn:hover {
    cursor: pointer;
}

.Create-Hdr-Save-Btn {
    background-color: var(--URBAN-SKYBLUE);
    border-color: var(--URBAN-SKYBLUE);
    color: var(--URBAN-WHITE);
}
.Create-Hdr-Save-Btn:hover {
    background-color: var(--URBAN-FOCUS-BLUE);
    border-color: var(--URBAN-FOCUS-BLUE);
}

.Create-Hdr-Pub-Btn {
    background-color: transparent;
    color: var(--URBAN-CLOUD);
    border-color: var(--URBAN-CLOUD);
    grid-column: 2;
}
.Create-Hdr-Pub-Btn:hover {
    color: var(--URBAN-WHITE);
    border-color: var(--URBAN-WHITE);
}

/* === */

.Draft-SubHdr-Wrapper {
    display: none;
    position: absolute;
    bottom: 0%;
    width: 100%;
    height: 3.5rem;
    padding: 0;
    z-index: 1;
    grid-template-columns: repeat(3, 1fr);
    border-top: 2px solid rgba(255,255,255,.1);
}

.Draft-SubHdr-Btn {
    height: inherit;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 0;
    transition: all .05s ease-out;
}

/* Old Preview Button. Keep around in case of revert. */
/* .Draft-Preview-Button {
    color: var(--URBAN-WHITE);
    background-color: var(--URBAN-PURPLE);
    border: 3px solid rgba(0,0,0,.2);
    border-radius: .5rem;
    padding: .7rem;
    font-size: 1.2em;
    display: flex;
    justify-content: center;
    align-items: center;

    box-shadow: 0px 0px 0px 2px rgba(0, 0, 0, .1);
    transition: all .05s ease-out;
} */

.SubHdr-Btn-Icon {
    width: 25px;
    height: 25px;
    fill: var(--URBAN-WHITE);
    margin: 5px;
}

.Draft-SubHdr-Btn > span {
    font-size: 9pt;
}

.DraftSH-Publish-Btn { 
    /* background-color: var(--URBAN-WHITE); */
    background-color: var(--node-bckgnd-color);
    /* color: var(--URBAN-GRAY);} */
    color: var(--URBAN-WHITE);}
.DraftSH-Publish-Btn:active {
    background-color: rgba(255,255,255,.6);}
.DraftSH-Publish-Btn > .SubHdr-Btn-Icon {
    /* fill: var(--URBAN-GRAY);} */
    fill: rgba(255,255,255,.9);}

.DraftSH-Save-Btn {
    background-color: var(--URBAN-BLUE);
    color: var(--URBAN-WHITE);}
.DraftSH-Save-Btn:active {
    background-color: var(--URBAN-DARK-BLUE);}

.DraftSH-Preview-Btn {
    background-color: var(--URBAN-PURPLE);
    color: var(--URBAN-WHITE);}
.DraftSH-Preview-Btn:active {
    background-color: var(--URBAN-ACTIVE-PURPLE);
}

/* .DraftSH-Preview-Btn > span {
    font-size: 1.4rem;
    font-weight: 600;
    padding-left: .5rem;
} */

@media (max-width: 800px) {
    #Create {
        position: relative;
        grid-template-rows: 3em auto;
    }
    #Create > main {
        border-right: 0;
        grid-column: 1 / 3;
        overflow-y: scroll;
    }
    .Create-Buffer {
        height: 4.5rem;
    }
    .draft-Preview {
        overflow-y: scroll;
        height: inherit;
        width: 100%;
        z-index: 1;
        grid-row-start: 2;
        left: 100%;
        position: absolute;
        background-color: var(--URBAN-WHITE);
        transition: left .5s 0s;
    }
    .draft-Preview.draft-Preview-Active {
        left: 0;
    }
    #Create header h1 {
        font-size: medium;
    }
    .Create-Hdr-Save-Btn {
        font-size: 1em;
        font-weight: 500;
    }
    #Create header > .Create-Hdr-Options {
        display: none;
    }
    .Draft-SubHdr-Wrapper {
        display: grid;
    }
}