._Important {
    --Imp-BGC: #fff; /*Background Color */
    --Imp-C: #000; /*Text Color*/
    --Imp-CBGC: #aaa; /*Background Color for Content*/
}

.Imp-Red {
    --Imp-BGC: rgb(227, 156, 170);
    --Imp-C: crimson;
    --Imp-CBGC: rgb(234, 185, 195);
}

.Imp-Yellow {
    --Imp-BGC: rgb(225, 219, 132);
    --Imp-C: darkgoldenrod;
    --Imp-CBGC: rgb(234, 232, 208);
}

._Important {
    border: 3px solid var(--Imp-C);
    border-radius: 10px;
    background-color: var(--Imp-BGC);
    padding: .5em;
    display: flex;
}

._Important > div:first-child {
    border: 5px solid var(--Imp-C);
    font-size: larger;
    font-weight: 700;
    color: var(--Imp-C);
    box-sizing: border-box;
    padding: .5em;
    border-radius: 10px;
    margin-right: .5em;
}

._Important > div:nth-child(2) {
    background-color: var(--Imp-CBGC);
    padding: 1em;
    border-radius: 10px;
    flex: 1;
}


@media screen and (max-width: 700px) {
    ._Important > div:first-child {
        display: none;
    }  
}