.AccountAccess {
    display: grid;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
}

.AA-Container {
    /* padding: 1em 10%; */
    background-color: var(--URBAN-WHITE);
    border-radius: 10px;
    box-shadow: 0 0 5px 0 rgba(0,0,0, .4);
}

.AA-Container em {
    color: var(--URBAN-GRAY)
}

.AA-Header > *, .AA-Content {
    margin-left: 10%;
    margin-right: 10%;
}

.AA-Header {
    padding: 1em 0;
    color: var(--URBAN-SOFT-GRAY);
    background-color: var(--URBAN-BLUE);
}

.AA-Header > *:first-child {
    margin-top: 0;
    margin-bottom: 0;
    font-size: 3em;
    font-family: Arial, Helvetica, sans-serif;
}

.AA-Content {
    padding-top: 2em;
}

.AA-Form label {
    color: var(--URBAN-GRAY);
    font-weight: 600;
}

.AA-Form input[type=text], .AA-Form input[type=password] {
    border: none;
    box-sizing: border-box;
    border-radius: 5px;
    background-color: var(--URBAN-CLOUD);
    color: var(--URBAN-GLOOM);
    padding: 5px;

    transition: background-color .25s;
    outline-color: transparent;
    width: 100%;
}

.AA-Form input[type=text]:focus, .AA-Form input[type=password]:focus   {
    outline-color: lightskyblue;
    background-color: white;
}

.AA-Btn-Wrapper {
    width: 100%;
    text-align: center;
    margin: 2.5em 0 1em 0;
}

.AA-Form button {
    color: var(--URBAN-WHITE);
    background-color: var(--URBAN-BLUE);
    text-transform: uppercase;
    width: 100%;
    height: 2.5em;
    font-weight: 600;
    letter-spacing: 5px;
    border: none;
    border-radius: 5px;
}

.AA-Form button:hover, .AA-Form button:focus {
    cursor: pointer;
    background-color: var(--URBAN-SKYBLUE);
    outline: var(--URBAN-LBLUE);
}

.Error_div {
    --Err-BGC: rgb(227, 156, 170);
    --Err-C: crimson;
    --Err-CBGC: rgb(241, 216, 221);

    padding: .3em;
    border-radius: 0 0 10px 10px;
    background-color: var(--Err-BGC);
    color: var(--Err-C);
    display: grid;
    grid-template-columns: 15px auto;
    align-items: center;
    margin: .2em 0;
}

.Error_Cluster > .Error_div:last-child {
    margin-bottom: 1.2em;
}

.Error_div > span {
    display: block;
    position: relative;
    background-color: var(--Err-CBGC);
    color: rgb(44, 8, 15);
    padding: 5px;
    border-radius: 10px;
    font-style: italic;
    font-weight: 400;
}

.Error_div:before {
    justify-content: center;
    content: "!";
    font-size: 1.5em;
    font-weight: 500;
    font-style: italic;
}

@media (max-width: 800px) {
    .AccountAccess {
        display: block;
    }
    .AccountAccess::before {
        content: "";
        display: block;
        width: 100%;
        height: 3px;
        background-color: var(--URBAN-DARK-BLUE);
        position: sticky;
        top: 0;
    }

    .AA-Form input[type=text], .AA-Form input[type=password] {
        font-size: larger;
    }

    .AA-Form button {
        height: 3em;
    }
}