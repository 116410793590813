.Settings-Container {
    display: grid;
    grid-template-columns: max(200px, 30%) auto;
    height: 100%;
    position: relative;
}

.Settings-Selectors {
    display: flex;
    justify-content: flex-end;
    padding: 2em;
    background-color: var(--URBAN-GRAY);
    color: var(--URBAN-WHITE);
}

.Settings-Selectors h1 {
    font-size: x-large;
}

.Settings-Selectors > div {
    /* background-color: var(--URBAN-LIGHT-GRAY); */
    width: min(13em, 100%);
}

.Settings-Section {
    padding: 2em;
    background-color: var(--URBAN-CODE-GRAY);
}

.Settings-Selectors-NavBtn-Wrappers {
    /* background-color: antiquewhite; */
    padding-left: 1rem;
}

.Settings-NavBtn {
    display: block;
    box-sizing: border-box;
    
    text-decoration: none;
    font-weight: 600;
    color: inherit;

    width: 100%;
    padding: .5rem;
    margin-bottom: .5em;
    border-radius: .25rem;
}

.Settings-NavBtn.Active {
    background-color: var(--URBAN-SKYBLUE);
    /* background-color: rgba(0,0,0,.25); */
    color: var(--URBAN-WHITE);
    /* color: var(--URBAN-SKYBLUE); */
    /* background-color: rgb(36, 36, 42); */
}

@media screen and (hover: hover) {
    .Settings-NavBtn:not(.Active):hover {
        background-color: rgba(0,0,0,.2);
    }
}


@media screen and (max-width: 600px) {
    .Settings-Selectors {
        grid-column: 1 / 3;
        justify-content: flex-start;
        padding: 1em;
        padding-top: 0;
    }
    .Settings-Selectors > div {width: 100%;}

    
    .Settings-Section {
        padding: 1em;
        padding-top: 0;
        box-sizing: border-box;
        position: absolute;
        left: 100%;
        width: 100%;
        transition: all .4s ease-out;
        min-height: 100%;
    }
    .Settings-Section.open {
        left: 0;
    }
}