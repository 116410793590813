.cmenu-btn {
    width: 100%;
}

.cmenu-btn:hover {
    cursor: pointer;
}

.cmenu-delete-btn {
    margin-top: .25em;
    margin-bottom: 0;
    color: var(--URBAN-RED);
    font-weight: 700;
    display: grid;
    padding: 0;
    border: 0;
    grid-template-columns: 1.25em auto;
    background-color: var(--URBAN-WHITE);
}

.cmenu-delete-btn > * {
    margin: 0;
}

.cmenu-delete-btn > *:last-child {
    padding-left: .5em;
    padding-right: .5em;
}

.CMB-Icon {
    display: inline-block;
    height: 1.25em;
    width: 1.25em;
    line-height: 1.25em;
    text-align: center;
    font-weight: 800;
}
.cmenu-delete-btn .CMB-Icon {
    background-color: var(--URBAN-RED);
    color: var(--URBAN-WHITE);
}

.cmenu-delete-btn .CMB-Icon, .cmenu-delete-btn > *:last-child {
    transition: all .05s ease-out;
}

.cmenu-delete-btn:hover > *:last-child {
    color: var(--URBAN-WHITE);
    background-color: var(--URBAN-RED);
}
.cmenu-delete-btn:hover .CMB-Icon {
    color: var(--URBAN-RED);
    background-color: var(--URBAN-WHITE);
}

.CMenu-2Split-Opt {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 5px;
    border-bottom: 1px solid var(--URBAN-CLOUD);
}