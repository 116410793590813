.Authorship {
    display: flex;
    align-items: center;
}

.Authorship > .Authorship-Link {
    display: flex;
    align-items: center;
    text-decoration: none;
    color: inherit;
}

.Authorship p {
    margin: 0;
    padding-left: max(1%, 10px);
    height: var(--USERPFP-SM-SZ);
    line-height: var(--USERPFP-SM-SZ);
    text-align: center;
    font-weight: 600;
}