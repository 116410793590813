.ATxtA {
    border: 0;
    padding: .3em;
    resize: none;
    width: 100%;
    min-height: 100%;
    box-sizing: border-box;
    overflow-y: hidden;
}

.ATxtA:focus {
    outline-color: var(--URBAN-FOCUS-BLUE);
}