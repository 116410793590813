.LoadOnFetch-Wrapper {
    position: absolute;
    width: 100%;
    height: 100%;
    display: grid;
    justify-content: center;
    align-content: center;
    background-color: rgba(0,0,0,.3);
}

.LoadOnFetch {
    text-align: center;
    background-color: var(--URBAN-GRAY);
    padding: 1vh 5vw 3vh 5vw;
    color: white;

    letter-spacing: .3ch;
    border-radius: .2em;
}

.LoadOnFetch > * {
    padding: 0 5vw;
}

.LoadOnFetch::after {
    content: "";
    display: block;
    width: 100% + 10vh;
    height: 5px;

    background: linear-gradient(to right, white 0%, 40%, transparent 48% 52%, 60%, white 100%);
    background-size: 300%;
    animation: loadBar 8s 0s infinite;
}

@keyframes loadBar {
    0%   { background-position: 100% 0%; }
    50%  { background-position: 0%   0%;}
    100% { background-position: 100% 0%; }
}