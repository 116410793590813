.URS-Documents-Container {
    /* display: flex; */
    /* flex-wrap: wrap; */
    /* justify-content: space-evenly; */
    display: grid;
    width: inherit;
    grid-template-columns: repeat(auto-fill, minmax(225px, 1fr));
    column-gap: 1em;
    row-gap: 1em;
}

.URS-PostDiv {
    text-align: center;
    margin: 2em 0 1em 0;
}

.URS-PostDiv.AllDone {
    font-style: italic;
    opacity: .75;
}

@media screen and (max-width: 700px) {
    .URS-Documents-Container {
        row-gap: 0em;
    }
}