._cid {
    box-sizing: border-box;
    min-height: 100%;
    padding: .5em;
    line-height: 1.7;
    /* border: 1px solid rgba(0,0,0,.5); */
    white-space: pre-wrap;
    word-break: break-all;
    max-width: 100%;
    overflow-x: hidden;
    border-radius: .25em;
    background-color: white;
}

@media screen and (hover:hover) {
    ._cid:hover {
        cursor: text;
    }
}

._cid:focus-visible {
    outline-color: #2364c6;
}

.txtobj {
    background-color: rgb(0, 0, 0, .13);
    border-radius: .2em;
    padding: .05rem .1rem;
    transition: background-color .1s;
}

.txtobj.A {
    color: #2364C6;
    text-decoration: underline;
    background-color: rgba(0,0,1,.13);
}

.txtobj.M,
.txtobj.C {
    font-family: monospace;
    font-size: .9rem;
    padding: .2rem;

    background-color: rgb(83, 83, 83);
    color: white;
}

.txtobj.focused {
    outline: 1px solid rgba(0,0,0,.5);
    /* background-color: white; */
}

/* .txtobj.I.focused { background-color: rgba();} */
/* .txtobj.B.focused { background-color: rgba();} */
.txtobj.A.focused { background-color: rgba(40, 73, 237, 0.05);}
/* .txtobj.S.focused { background-color: rgba();} */
/* .txtobj.U.focused { background-color: rgba();} */
.txtobj.M.focused,
.txtobj.C.focused { background-color: rgba(56,56,56);}

.txtobj.A.focused,
.txtobj.A .focused {
    outline-color: #2364C6;
}
.txtobj.A .focused {outline-offset: 1px;}

