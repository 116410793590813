.ArtCard {
    position: relative;
    max-width: 600px;
    width: 90%;

    border-left: gold solid 1em; 
    background-color: aliceblue;
    border-radius: 0 15px 15px 0;
    padding-left: 1em;
}


.ArtCard h1 {
    font-size: 1.5em;
}

.CardImage {
    position: relative;
    width: 10px;
    height: 100%;
    background-color: darkred;
}