#Signup {
    grid-template-columns: 50%;
}

#Signup .AA-Header > *, #Signup .AA-Content {
    margin-left: 5%;
    margin-right: 5%;
}


#Signup .AA-Container {
    min-height: 500px;
}

#Signup .AA-Btn-Wrapper {
    width: 100%;
    margin: 0;
}

#Signup .AA-Form {grid-area: form}
#Signup .AA-C-Discl {grid-area: discl}
#Signup .AA-C-Errs {grid-area: errors}

#Signup .AA-Content {
    display: grid;
    grid-template-areas: 
        'form discl'
        'errors errors'
    ;
    grid-template-columns: 1fr 1fr;
    column-gap: 1em;
    margin-bottom: 2em;
}

#Signup .AA-C-Discl {
    background-color: var(--URBAN-CODE-GRAY);
    color: var(--URBAN-WHITE);
    padding: .7em;
    margin: 1.2em;
    border-radius: 5px;
}

#Signup .AA-C-Discl > h3 {
    margin-top: 0;
}


@media (max-width: 1000px){
    #Signup .AA-Content {
        grid-template-areas: 
            'form'
            'errors'
            'discl'
        ;
        grid-template-columns: 1fr;
    }
}

@media (max-width: 800px) {
    #Signup .AA-Container {
        min-height: 100%;
    }
}

#Signup #signup_code {
    max-width: 100px;
}