.toolbar {
    display: flex;
    flex-direction: column;
    position: sticky;
    top: -1px;
    z-index: 0; /*1 when sticky*/
    justify-content: space-between;
    height: 2.5em;
    /* padding: .5em .3em; */
    margin: .5em .3em;
    transition: 
        margin .5s ease, 
        height .1s ease-out;
    overflow: hidden;
}

.toolbar > * {
    min-height: 2.5em;
}

.toolbar .CID-Options::-webkit-scrollbar {
    display: none;
}

.toolbar .CID-Options {
    scrollbar-gutter: stable;
}

.toolbar-options {
    display: inline-flex;
    position: relative;
    width: 100%;
    height: 2.5em;
    bottom: 0%;
    transition: bottom .25s ease-in-out; /* toolbar-tray also has this transition */
}

/* === STICKY === */

.toolbar.sticky,
.toolbar.trayOpen {
    z-index: 1;
}

.toolbar.sticky {
    margin: .5em 0em;
}

.toolbar.sticky .CID-Options,
.toolbar.sticky .Create-Image-Budget,
.toolbar.sticky .toolbar-tray {
    background-color: var(--node-GRAY);
}

.toolbar.sticky .Create-Image-Budget .CIB-Used {
    background-color: var(--URBAN-DISABLED-GRAY);
}

.toolbar.sticky .CID-Options,
.toolbar.sticky .toolbar-tray {
    border-radius: 0 0 0 .1em;
}

.toolbar.sticky .Create-Image-Budget {
    border-radius: 0 0 .1em 0;
    margin-left: 0;
}

/* .toolbar.sticky .Create-Image-Budget .CIB-Cap-Wrapper {
    transform: scale(.75);
} */

/* = END STICKY = */

.CID-Options {
    display: flex;
    flex: 1;
    border-radius: .7em;
    background-color: var(--URBAN-GLOOM);
    /* border: 1px solid; */
    transition: border-radius .9s, 
        border-top-right-radius .9s,
        border-bottom-right-radius .9s,
        background-color .5s;
    overflow-x: scroll;
}

.CID-Grouped-Btns {
    padding: 0;
    margin: 0;
    background-color: transparent;
}

.CID-Options svg {
    padding-top: .3em;
    width: 60%;
}

.CID-Options > button,
.CID-Options .CID-Grouped-Btns > button:not(.AuxBtn) {
    /* width: 3em; */
    aspect-ratio: 1;
    background-color: transparent;
    border: none;
    border-radius: .25em;
    color: white;
    fill: white;
    margin-right: .1em;
}

.CID-Options .CID-Grouped-Btns > button.AuxBtn {
    overflow: hidden;
    display: none;
}

.CID-Options > button.active,
.CID-Options .CID-Grouped-Btns > button.active:not(.AuxBtn) {
    background-color: white;
    color: black;
    fill: black;
}

.CID-Options .CID-Grouped-Btns > button.active ~ button.AuxBtn {
    display: inline-flex;
    background-color: transparent;
    border: none;
}

.CID-Options > button:disabled,
.CID-Options .CID-Grouped-Btns > button:disabled:not(.AuxBtn) {
    color: rgba(255,255,255,.25);
    fill: rgba(255,255,255,.25);
}

@media screen and (hover:hover) {
    .CID-Options > button:hover:enabled,
    .CID-Options .CID-Grouped-Btns > button:hover:enabled:not(.AuxBtn) {
        cursor: pointer;
        background-color: rgba(255,255,255,.3);
    }

    .CID-Options > button.active:hover,
    .CID-Options .CID-Grouped-Btns > button.active:hover:not(.AuxBtn) {
        background-color: rgba(255,255,255,.7);
    }
}

/* === === */

.Create-Image-Budget {
    font-weight: 700;
    display: flex;
    align-items: center;
    color: var(--URBAN-LIGHT-GRAY);
    background-color: var(--URBAN-GLOOM);
    text-align: right;
    padding: .25em;
    padding-right: .4em;
    margin-left: .5em;
    font-size: 1em;
    border-radius: .7em;
    transition: 
        border-radius .9s, 
        margin-left .5s,
        background-color .5s;
}

.Create-Image-Budget .CIB-Used {
    font-size: medium;
    color: var(--URBAN-SOFT-GRAY);
    background-color: #505050;
    padding: .2em .5em;
    margin: 0 .2em 0 .2em;
    border-radius: .5em;
    min-width: 1.5em;
    text-align: center;
    transition: background-color .5s;
}


.Create-Image-Budget .CIB-Cap {
    font-size: medium;
    color: var(--URBAN-SOFT-GRAY);
    margin: 0 .3em;
}

/* .CIB-Cap-Wrapper {
    transition: transform .5s;
} */

.toolbar-tray {
    position: absolute;
    /* top: calc(2 * 2.5em); */
    bottom: 100%;
    background-color: var(--URBAN-GLOOM);
    width: 100%;

    border-radius: .7em;
    transition: 
        bottom .15s ease-in-out,
        border-radius .9s, 
        background-color .5s;
}

.toolbar.trayOpen .toolbar-options {
    bottom: 100%;
}

.toolbar.trayOpen .toolbar-tray {
    bottom: 0;
}