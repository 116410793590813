.SA-LimitInput > input {
    margin-left: 0;
    background-color: #2f2f2f;
    color: white;
}


.SA-LimitInput > input:focus {
    color: black;
}

.SA-LimitInput.limit-input-Invalid > span,
.SA-LimitInput.limit-input-Invalid > input,
.SA-LimitInput.limit-input-Invalid > input:focus,
.SA-LimitInput.limit-input-Invalid > input:focus ~ span {
    color: rgba(255,83,83,1);
}
.SA-LimitInput.limit-input-Invalid > input:focus {
    outline-color: rgba(255,83,83,1);
}

/* Random */

.SA-ProfilePicture-Container {
    display: block;
}

.SA-ProfilePicture {
    width: min(250px, 100%);
    display: grid;
    column-gap: .75em;
    grid-template-columns: 2fr auto;
}

.SA-NMIS-Container {
    padding: .25em;
    border-radius: .5em;
    background-color: var(--URBAN-GLOOM);
}

.Account-Image-Options > div {
    width: 2em;
}

@media screen and (max-width: 600px) {
    .SA-ProfilePicture-Container {
        display: flex;
        justify-content: center;
    }
    .Account-Image-Options > div {
        width: 2.3em;
    }
}

.Account-PFP-Options > div {
    margin-bottom: .5em;
}

.Account-Banner-Options {
    display: flex;
    justify-content: flex-end;
    margin-top: .25em;
    margin-bottom: .25em;
}

.Account-Banner-Options > div { 
    display: inline-block;
    margin-right: .5em;
}

.Account-LI-TextArea-Container {
    display: flex;
}

.LI-TextArea {
    box-sizing: border-box;
    resize: none;
    width: 100%;
    height: calc(1.5em*7);
    padding: .5em;

    border: 0;
    border-radius: 5px;

    font-family: Arial, Helvetica, sans-serif;

    color: white;
    font-weight: 600;
    background-color: #2f2f2f;
    transition: background-color .25s;
    outline-color: transparent;
    outline-style: solid;
    outline-width: 3px;
}

.LI-TextArea:focus-visible {
    color: black;
    outline-color: var(--URBAN-FOCUS-BLUE);
}

.LI-TextArea:focus-visible ~ span {
    color: var(--URBAN-FOCUS-BLUE);
}

.LI-Span {
    font-weight: 700;
    color: #777;
    margin: 5px;
    margin-top: 0;
    min-width: 40px;
    text-align: center;
    box-sizing: border-box;
    transition: color .25s;
}

.LI-TextArea:focus {
    outline-color: lightskyblue;
    background-color: white;
}

.LI-TextArea:focus ~ * > .LI-Span {
    color: var(--URBAN-FOCUS-BLUE);
}