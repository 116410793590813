.IU-Dialog {
    border: 3px solid rgba(0,0,0,.5);
    border-radius: .5em;
    /* box-shadow: 0 0 5px #000c; */
    background-color: var(--URBAN-GLOOM);
    width: min(30rem, 80%);
}

.IU-Dialog::backdrop {
    background-color: rgba(0,0,0,.5);
}

.IU-Dialog > h1 {
    margin-top: 0;
    color: var(--URBAN-WHITE);
}

.IU-Dialog > input[type=file] {
    display: none;
}

.IU-Dialog > label {
    display: block;
    box-sizing: border-box;
    /* height: 20em; */
    width: 100%;
    aspect-ratio: 1.5;
    padding: 1em;

    background-color: rgba(0,0,0,.7);

    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    border: 4px solid var(--URBAN-WHITE);
    border-radius: 5px;
}

.IU-Dialog > label > div {
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    border-width: 5px;
    border-style: solid;
    transition: all .3s ease-out;
    
    display: grid;
    justify-content: center;
    align-content: center;
    font-size: 2em;
}

.IU-Empty {
    border-color: white;
    color: white;
}

.IU-Filled {
    border-color: transparent;
    color: transparent;
}

.IU-Dialog > label:active,
.IU-Dialog > label:active > div {
    cursor: pointer;
}
.IU-Dialog > label:active > div {
    transform: scale(1.03);
}

.IU-Dialog > label:active > div {
    color: var(--URBAN-HOVER-BLUE);
    border-color: var(--URBAN-HOVER-BLUE);
    outline-color: var(--URBAN-HOVER-BLUE);
    background-color: rgba(0,0,0,.8);
}

@media only screen and (hover: hover) {
    .IU-Dialog > label:hover,
    .IU-Dialog > label:hover > div {
        cursor: pointer;
    }
    .IU-Dialog > label:hover > div {
        transform: scale(1.03);
    }

    .IU-Dialog > label:hover > div {
        color: var(--URBAN-HOVER-BLUE);
        border-color: var(--URBAN-HOVER-BLUE);
        outline-color: var(--URBAN-HOVER-BLUE);
        background-color: rgba(0,0,0,.8);
    }
}
/* === */

.IU-Btns {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    column-gap: 1em;
    row-gap: 1em;
}

.IU-Btns > * {
    height: 3em;
    font-size: medium;
    font-weight: 700;
    letter-spacing: 3px;
    border-radius: 5px;
    border: none;
    transition: all .1s ease-in-out;
    color: var(--URBAN-WHITE);
}
.IU-Btns > *:hover:not(:disabled) {
    cursor: pointer;
}

.IU-Btn-Confirm {
    background-color: var(--URBAN-SKYBLUE);
    grid-column: 1 / 4;
}
.IU-Btn-Confirm:disabled {
    /* background-color: var(--URBAN-DISABLED-BLUE); */
    background-color: rgba(86, 85, 85, 0.54);
}
.IU-Btn-Confirm:disabled .IU-Btn-Confirm-Budget-Counter {
    background-color: rgba(255, 255, 255, 0.088);
}
.IU-Btn-Confirm:disabled * {
    color: rgba(255, 255, 255, 0.268);
}
.IU-Btn-Confirm:hover:not(:disabled) {
    background-color: var(--URBAN-HOVER-BLUE);
}

.IU-Btn-Confirm.IU-Btn-Error { background-color: rgb(186 36 65); }
.IU-Btn-Confirm.IU-Btn-Error:hover { cursor: not-allowed;}
.IU-Btn-Confirm.IU-Btn-Error .IU-Btn-Confirm-ConfirmText{
    font-size: small;
    letter-spacing: .03rem;
}
.IU-Btn-Confirm.IU-Btn-Error * { color: var(--URBAN-WHITE); }
.IU-Btn-Confirm.IU-Btn-Error .IU-Btn-Confirm-Budget-Counter { background-color: rgba(240, 94, 128, 0.796); }

.IU-Btn-Cancel {
    grid-column: 4 / 6;
    /* background-color: var(--URBAN-LIGHT-GRAY); */
    background-color: transparent;
}
.IU-Btn-Cancel:hover {
    background-color: var(--URBAN-HOVER-WHITE);
}

/* == == */

.IU-Btn-Confirm {
    display: flex;
    padding-left: 1rem;
    padding-right: 1rem;
    justify-content: space-between;
    align-items: center;
}

.IU-Btn-Confirm-Budget-Span {
    color: rgba(255,255,255,0.5);
}

.IU-Btn-Confirm-Budget-Counter {
    display: inline-block;
    background-color: rgba(255,255,255,.3);
    padding: .2rem .5rem;
    margin: 0 .2rem;
    border-radius: .5em;
    min-width: 1.5em;
    text-align: center;
    letter-spacing: normal;
}

.IU-Btn-Confirm-Budget-Counter,
.IU-Btn-Confirm-Budget-Max {
    color: var(--URBAN-WHITE);
}

@media screen and (max-width: 600px) {
    .IU-Btns > button {
        grid-column: 1 / 6;
    }
}