#Admin {
    display: grid;
}

.Code-Table-Container {
    position: relative;
    max-height: 500px;
    overflow-y: auto;
    overflow-x: auto;
    margin: .5em;

    border-radius: .5em;
    border:2px solid rgba(0,0,0,.1);
}

#Admin thead {
    position: sticky;
    top: 0px;
    background-color: white;
}

#Admin .Code-Table {
    box-sizing: border-box;
    border-spacing: 0;
    border-collapse: separate;
    border-radius: .5em;
    /* border: 2px solid rgba(0,0,0,.1); */
    /* margin: .5em; */

    width: max(100%, 700px);

    text-align: left;

    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

#Admin .Code-Table thead tr th {
    border-bottom: 3px solid rgba(0,0,0,.2);
    padding-top: .5em;
    padding-bottom: .5em;

    color: rgb(70, 70, 70);
}

#Admin .Code-Table tbody tr td {
    padding-top: .4em;
    padding-bottom: .4em;
    color: rgb(66, 66, 66);
}

#Admin .Code-Table tr td:first-child,
#Admin .Code-Table thead tr th:first-child {
    padding-left: .5em;
}
#Admin .Code-Table tr td:last-child,
#Admin .Code-Table thead tr th:last-child {
    padding-right: .25em;
}
#Admin .Code-Table tbody tr:not(:last-child) td {
    border-bottom: 1px solid rgba(0,0,0,.1);
}

#Admin .Code-Table .generateCodeBtn {
    font-weight: 700;
    color:white;
    background-color: rgb(20, 107, 165);
    border: 2px solid rgb(60, 145, 179);
    border-radius: .2em;
    outline: none;
    padding: .2em .4em;
}

#Admin .Code-Table .generateCodeBtn:hover {
    cursor: pointer;
}

#Admin .Code-Table tfoot td {
    text-align: center;
    padding-top: 0;
    padding-bottom: 0;
}
#Admin .Code-Table tfoot button {
    background-color: hsl(0, 0%, 100%);
    border: none;
    padding: .75em 1.5em;
    font-weight: 600;
    color: rgb(20, 106, 163);
}
#Admin .Code-Table tfoot button:hover {
    background-color: hsl(204, 78%, 92%);
    cursor: pointer;
}

#Admin .Code-Table tbody tr td {
    background-color: hsl(0, 0%, 100%);
}
#Admin .Code-Table tbody tr:hover td {
    background-color: hsl(0, 0%, 92%);
}

#Admin .Code-Table tr.expired td {
    background-color: hsl(0, 0%, 85%);
}
#Admin .Code-Table tr.expired:hover td {
    background-color: hsl(0, 0%, 80%);
}

#Admin .Code-Table tr.used td {
    background-color: hsl(140, 91%, 87%);
}
#Admin .Code-Table tr.used:hover td {
    background-color: hsl(140, 91%, 82%);
}