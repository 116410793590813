._Notif-Layer {
    z-index: 9990;
    position: absolute;
    bottom: 1em;
    right: 2em;
    width: min(400px, 30%);
    /* background-color: crimson; */
    display: flex;
    flex-direction: column;
}

._Notif {
    border-radius: .1em;
    background-color: var(--URBAN-WHITE);
    margin: 1em 0 0 0;
    box-shadow: 0px 0px 3px rgba(0 0 0 / 30%);
}

._Notif > h4 {
    box-shadow: 0px 1px 1px rgba(0 0 0 / 20%);
}

._Notif > p, ._Notif > h4{
    margin: 0;
    padding: .5em .8em;
}

._Notif:not(.LoadNotif) {
    animation: NotifFade 1s 4s, NotifCollapse .1s 4.5s;
    animation-fill-mode: forwards;
}

@keyframes NotifFade {
    from { opacity: 1; }
    to   { opacity: 0; }
}

@keyframes NotifCollapse {
    from {}
    to {
        height: 0;
        margin: 0;
        display: none;
    }
}

/* -- Error Notification -- */

.ErrorNotif > h4 {
    background-color: var(--URBAN-ERROR-RED-BGC);
}
.ErrorNotif > p {
    background-color: var(--URBAN-ERROR-RED-CBGC);
    /* color: var(--URBAN-ERROR-RED-C); */
}

/* -- Load Notification -- */

.LoadNotif::after {
    content: "";
    display: block;
    width: 100%;
    height: 4px;
    background-color: var(--URBAN-GRAY);
    background: linear-gradient(to right, var(--URBAN-GRAY) 0%, 47%,transparent 48% 51%, 52%, var(--URBAN-GRAY) 100%);
    background-size: 300%;

    animation: loadNotif 2s 0s ease-in infinite;
}


@keyframes loadNotif {
    0%   { background-position: 100% 0%; }
    100%  { background-position: 0%   0%;}
}

.SuccessLoadNotif::after, .DoneLoadNotif::after, .FailureLoadNotif::after {
    content: "";
    display: block;
    width: 100%;
    height: 4px;
    
    animation: loadPulse 1s 0s ease-in infinite alternate-reverse;
}

.SuccessLoadNotif::after, .DoneLoadNotif::after {
    background-color: var(--URBAN-FOCUS-BLUE);
}

.FailureLoadNotif::after {
    background-color: var(--URBAN-RED);
}

@keyframes loadPulse {
    0% {opacity: 1.0;;}
    100% {opacity: .5;}
}

@media (max-width: 800px) {
    ._Notif-Layer {
        width: 80%;
        left: 10%;
        right: 10%;
        justify-content: center;
    }

    ._Notif > p, ._Notif > h4{
        /* margin: 0; */
        padding: 1rem 1.3rem;
    }
}