.Empty.doc-Banner-Placeholder,
.Empty.HeadHeader,
.Empty.HeadSubHeader,
.Empty.Authorship > *,
.Empty.Time {
    background-color: lightgray;
}

.Empty.Doc-Grid {
    visibility: hidden;
    animation: test 0s 1s forwards;
}

@keyframes test {
    from {visibility: hidden;}
    to {visibility: visible;}
}

.Empty.HeadHeader {height: 2rem;}
.Empty.HeadSubHeader {
    height: 1.5rem;
    width: 95%;
}

.Empty.username  {
    margin: 0;
    margin-left: max(1%, 10px);
    height: var(--USERPFP-SM-SZ);
    width: max(10%, 150px);
}

.Empty.p {
    margin: 1.5em 0;
}
.Empty.p * {
    height: 1rem;
    background-color: lightgray;
    margin: .15em;
}

.Empty.TimePosted {
    display: flex;
    justify-content: center;
}

.Empty.Time {
    height: 1.25rem;
    width: max(25%, 125px);
}