.Doc {
    container-type: inline-size;
}

.Doc-Grid {
    display: grid;
    justify-content: center;
    grid-template-columns: min(100%, 850px);
    background-color: var(--URBAN-WHITE);

    padding-top: 1em;
}

.Doc-Content {
    margin: 3%;
}

.TimePosted {
    text-align: center;
}

@container (max-width: 850px){
    .Doc-Grid {
        padding-top: 0;
    }
}

@media (max-width: 800px) {
    .Doc-Content {
        margin: 5%;
    }
    p {
        font-size: 1rem;
    }
}

.HeadHeader, .HeadSubHeader {
    margin-top: 0;
}

.HeadHeader {
    margin-bottom: .25em;
}

.HeadSubHeader {
    margin-bottom: 1.5em;
}

code {
    padding: 0.15em 0.30em;
    background-color: var(--URBAN-GLOOM);
    color: hsl(0,0%,90%);
    border-radius: 0.2em;
    margin: 0.1em;
    font-size: 0.85em;
}