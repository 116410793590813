.NoModal-Image-Selector > input {
    display: none;
}

.NoModal-Image-Selector label {
    box-sizing: border-box;
    display: block;
    background-color: black;
    border: 3px solid rgba(255,255,255,.1);
    width: 100%;
    aspect-ratio: 1;
    border-radius: .5em;

    background-repeat: no-repeat;
    background-size: contain;
    background-origin: border-box;
}

.NMIS-Center-Prompt-Container {
    height: 100%;
    /* padding: min(1%, .5em); */
    display: flex;
    justify-content: center;
    align-items: center;
}

.NMIS-Center-Prompt {
    text-align: center;
    border: 3px solid rgba(255,255,255,.8);
    color: rgba(255,255,255,.8);
    border-radius: .25em;
    padding: .5em;
    transition: all .1s linear;
}

.NoModal-Image-Selector label:hover {cursor: pointer;}

.NoModal-Image-Selector label:hover         .NMIS-Center-Prompt,
.NoModal-Image-Selector label:focus-visible .NMIS-Center-Prompt,
.NoModal-Image-Selector.invalid label:hover         .NMIS-Center-Prompt,
.NoModal-Image-Selector.invalid label:focus-visible .NMIS-Center-Prompt,
.NoModal-Image-Selector.hasImage label .NMIS-Center-Prompt
{
    border-color: var(--URBAN-FOCUS-BLUE);
    color: var(--URBAN-FOCUS-BLUE);
    background-color: rgba(0,0,0,.8);
}

.NoModal-Image-Selector.hasImage .NMIS-Center-Prompt-Container {
    opacity: 0;
}

.NoModal-Image-Selector.hasImage label:hover         .NMIS-Center-Prompt-Container,
.NoModal-Image-Selector.hasImage label:focus-visible .NMIS-Center-Prompt-Container
{
    opacity: 1;
}

.NoModal-Image-Selector.invalid label {
    outline: 3px solid rgba(255,83,83,1);
}
.NoModal-Image-Selector.invalid label .NMIS-Center-Prompt {
    color: rgba(255,83,83,1);
    border-color: rgba(255,83,83,1);
}