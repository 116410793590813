@media print {
    ._Layout {
        height: auto;
        position: relative;
        display: block;
    }
    ._Layout nav {
        display: none;
    }
    ._Layout-Content {
        overflow-y: visible;
    }
}