.UserStats-Container {
    display: flex;
    justify-content: space-evenly;
    padding: 2% 0;
    margin-bottom: 2%;
}

.US-Stat {
    margin: min(2%, 10px) 0;
}

.US-Stat > * {
    margin: 0;
    text-align: center;
}

.US-Stat h1 {
    color: var(--URBAN-SKYBLUE);
    font-size: min(6vw, 3em);
    position: relative;
    font-family: Arial, Helvetica, sans-serif;
}

.US-Stat h1 > span {
    /* position: absolute; */
    font-size: 60%;
    /* bottom: 3px; */
}

.US-Stat h3 {
    font-size: min(3.5vw, 1.17em);
}