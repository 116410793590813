.Empty_Node {
    display: grid;
    justify-content: center;
    align-content: center;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(
        150deg,
        var(--URBAN-SOFT-WHITE) 10%,
        transparent 10% 20%,
        var(--URBAN-SOFT-WHITE) 20% 30%,
        transparent 30% 40%,
        var(--URBAN-SOFT-WHITE) 40% 50%,
        transparent 50% 60%,
        var(--URBAN-SOFT-WHITE) 60% 70%,
        transparent 70% 80%,
        var(--URBAN-SOFT-WHITE) 80% 90%,
        transparent 90%
    );
}

.Empty_Node > p {
    margin: 0;
    vertical-align: center;
    padding: .5em 1em;
    background-color: gray;
}