.UserBio {
    background-color: var(--URBAN-WHITE);
    padding: .5em;
    border-radius: 5px;
    box-shadow: 1px 1px 5px rgba(0,0,0,.3);
}

.UserBio > * {
    margin: 0;
}

.UserBio > *:first-child {
    color: var(--URBAN-GRAY);
    position: relative;
}

.UserBio > p {
    margin: 1em 0;
}

.UB-EmptyBio {
    opacity: .7;
}

.UserBio > h2 {
    display: flex;
    justify-content: space-between;
    align-content:center;
}

.UB-ECButton {
    border: 0;
    border-radius: 5px;
    background-color: transparent;
    color: var(--URBAN-GRAY);
    font-weight: 700;
    padding: .5em;
}

.UB-ECButton:hover {
    color: var(--URBAN-LIGHT-GRAY);
    cursor: pointer;
}

.LI-TextArea {
    box-sizing: border-box;
    resize: none;
    width: 100%;
    height: calc(1.5em*7);
    padding: .5em;

    border: 0;
    border-radius: 5px;

    color: var(--URBAN-GLOOM);
    font-weight: 600;
    background-color: var(--URBAN-CLOUD);
    transition: background-color .25s;
    outline-color: transparent;
}

.LI-Span {
    font-weight: 700;
    color: #777;
    margin: 5px;
    margin-top: 0;
    min-width: 40px;
    text-align: center;
    box-sizing: border-box;
    transition: color .25s;
}

.LI-TextArea:focus {
    outline-color: lightskyblue;
    background-color: white;
}

.LI-TextArea:focus ~ * > .LI-Span {
    color: var(--URBAN-FOCUS-BLUE);
}

.UB-SubmitDiv {
    display: flex;
    justify-content: right;
    align-content: center;
    margin-top: .3em;
}

.UB-SubmitDiv > button {
    padding: .3em 1em;
    background-color: var(--URBAN-FOCUS-BLUE);
    color: var(--URBAN-WHITE);
    font-weight: 700;
    border: 0;
    border-radius: .2em;
}

.UB-SubmitDiv > button:hover:not(:disabled) {
    cursor: pointer;
    background-color: var(--URBAN-HOVER-BLUE);
}

.UB-SubmitDiv > button:disabled {
    background-color: var(--URBAN-DISABLED-BLUE);
}

.UB-ButtonSubmitting {
    background-color: var(--URBAN-DISABLED-BLUE);
    background-image: linear-gradient(to right, transparent 0 35%, var(--URBAN-WHITE) 43% 45%, transparent 50%);
    background-size: 400%;
    animation: submitButton 2s 0s infinite;
}

@keyframes submitButton {
    0%   { background-position: 100% 0%; }
    100%  { background-position: 0%  0%;}
}