.Create-Publish-Dialog-Body {
    color: var(--URBAN-GRAY);
}

.Create-Publish-Dialog-Body > h1 {
    margin-top: 0;
    margin-bottom: 0;
}

.Create-Publish-Dialog-Body > p {
    font-size: large;
    margin-top: 1.5rem;
    margin-bottom: 2rem;
}

.Create-Publish-Dialog-Buttons {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    column-gap: 1rem;
    row-gap: 1em;
}

.Create-Publish-Dialog-Buttons button {
    height: 3rem;
    font-size: medium;
    border-radius: .5em;
}
.Create-Publish-Dialog-Buttons button:hover {
    cursor: pointer;
}

.Dialog-Publish-Btn {
    grid-column: 1 / 4;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--URBAN-SKYBLUE);
    color: var(--URBAN-WHITE);
    border: 0;
}
.Dialog-Publish-Btn:hover {
    background-color: var(--URBAN-HOVER-BLUE);
}

.Dialog-Publish-Btn > span {
    margin-left: .5rem;
    font-size: larger;
}

.Pub-Btn-Book-Icon {
    display: inline-block;
    height: inherit;
    aspect-ratio: .4;
    fill: var(--URBAN-WHITE);
}

.Dialog-Close-Btn {
    grid-column: 4 / 7;
    border: 0;
    background-color: inherit;
    transition: all .1s ease-out;
}


.Dialog-Close-Btn:hover,
.Dialog-Close-Btn:active {
    background-color: rgba(0,0,0,.1);
}

.Dialog-Close-Btn > span {
    font-size: larger;
}

@media screen and (max-width: 400px) {
    .Create-Publish-Dialog-Buttons > button {
        grid-column: 1 / 7;
    }
}