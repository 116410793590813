.DTL-Toggle {
    display: inline-block;
    text-align: center;
    height: 1.5em;
    text-align: left;
    /* color: black; */
    border-bottom: 1px solid var(--URBAN-CLOUD);
}

.DTL-Toggle:hover {
    background-color: var(--URBAN-CLOUD);
    cursor: pointer;
}

.DTL-Node-Summary-Wrapper {
    background-color: var(--node-bckgnd-color);
    color: var(--URBAN-WHITE);
    padding: 0 .3rem .5rem .3rem;
    margin-bottom: .1rem;
}

.DTL-Node-Summary-Wrapper > label {
    font-size: x-small;
    opacity: .75;
}

.DTL-Node-Summary-Wrapper > input {
    padding: .5rem;
    padding-left: 0;
    box-sizing: border-box;
    width: 100%;
    background-color: #fff0;
    color: white;
    border-radius: .1rem;
    border: none;
    vertical-align: middle;
    outline: none;
    border-bottom: 2px solid rgba(255,255,255,.1);
}

.DTL-Node-Summary-Wrapper > input::placeholder {
    font-style: italic;
}

.DTL-Node-Summary-Wrapper > input:focus-visible {
    caret-color: var(--URBAN-DISABLED-FOCUS-BLUE);
    border-color: var(--URBAN-FOCUS-BLUE);
}