#User { 
    min-height: 100%; 
    background-color: var(--URBAN-WHITE);
    /* padding: 2rem 5rem; */
    display: flex;
    justify-content: center;
}

#User .SmallWidth {
    display: none;
}

.User-Container {
    /* background-color: crimson; */
    /* height: 100%; */
    width: min(1200px, 100%);
    display: grid;
    grid-template-columns: 4fr 1.75fr;
    column-gap: 2rem;
    padding: 2rem;
    /* padding-bottom: 0; */
}

.User-Container > main {
    display: flex;
    flex-direction: column;
}

/* .User-Container > aside { */
    /* max-width: 15rem; */
    /* background-color: darkcyan; */
/* } */

/* SubComponents */

.User-Content {
    flex: 1;
    box-sizing: border-box;
    width: 100%;
    /* height: 20em; */
    border: 3px solid rgba(0,0,0,.1); 
    border-top: 0;
    border-radius: 0 0 .5rem .5rem;
}

.User-Content-Container {
    padding: 1em;
    min-height: 20rem;
}

.User-Selections {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-template-rows: 3rem;
}

.User-Selections > button {
    border: 0;
    border-bottom: 3px solid rgba(0,0,0,.1);
    color: rgba(0,0,0,.5);
    background-color: var(--URBAN-WHITE);
    font-weight: 600;
}

.User-Selections > button:active {
    transition: all 1s linear;
}

.User-Selections > button.UsrSlct-Active {
    border: 3px solid rgba(0,0,0,.1);
    border-bottom: 0;
    color: var(--URBAN-SKYBLUE);
}

.User-Selections > button:not(.UsrSlct-Active):hover {
    background-color: rgba(0,0,0,.1);
    cursor: pointer;
}

.User-Selections > button:first-child.UsrSlct-Active {
    border-left: 0
}
.User-Selections > button:last-child.UsrSlct-Active {
    border-right: 0;
}

@media screen and (max-width: 1000px) {
    .User-Container > main {
        grid-column: 1 / 3;
    }
    .User-Container > aside {
        display: none;
    }

    #User .SmallWidth {
        display: contents;
    }
}

@media screen and (max-width: 700px) {
    .User-Container {
        padding: 0;
    }
    .User-Content {
        border: 0; 
        border-top: 0;
        border-radius: 0;
    }

    .User-Content-Container {
        padding: 1em .5em;
    }

    .User-Container > main > .UserBanner {
        border-radius: 0;
    }
    .User-Container > main .UD-Options.FullScreen {display: none;}
    .User-Container > main .UD-Options.SmallScreen {display: contents;}
}

.UC-Bio-Header {
    display: flex;
    /* justify-content: space-between; */
    /* align-items: stretch; */
    height: 2em;
    padding: 1em 1em 0 1em;
}

.UC-Bio-Header h3 {
    margin: 0;
    margin-right: 1em;
    flex: 1;
    color: var(--URBAN-GRAY);
    border-bottom: 2px solid var(--URBAN-GRAY);
}

.UC-Bio-Body {
    padding: 0 1em;
    margin-bottom: 1.5em;
}