.SubUserRender-Selections-Btns {
    /* display: flex; */
    display: grid;
    grid-template-columns: 1fr 1fr;
    /* column-gap: .5em; */
    padding: .25em;
    background-color: rgba(0,0,0,.2);
    height: 2rem;
    margin-bottom: 1em;
}

.SubUserRender-Selections-Btns > button {
    /* flex: 1; */
    font-weight: 600;

    background-color: transparent;
    /* color: var(--URBAN-GRAY); */
    color: rgba(50,50,50,.5);
    border: none;
    transition: all .1s linear;
}

.SubUserRender-Selections-Btns > button:hover:not(:disabled) {
    cursor: pointer;
    /* background-color: var(--URBAN-WHITE); */
}

.SubUserRender-Selections-Btns > button:disabled {
    /* background-color: var(--URBAN-WHITE); */
    background-color: var(--URBAN-SKYBLUE);
    color: var(--URBAN-WHITE);
}