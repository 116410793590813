.UserCard-Large {
    display: grid;
    grid-template-rows: repeat(2, 7em);
}

.UC-L-Top {
    position: relative;
    display: flex;
    color: var(--URBAN-WHITE);
}

.UC-L-Top > *:last-child {
    position: absolute;
    margin: 0;
    margin-right: 10px;
    align-content: center;
    top:0;
    right: 0;
    font-size: 3em;
}

.UC-L-Top > *:nth-child(2){
    flex: 1;
    padding-left: 1em;
    background-color: var(--URBAN-GRAY);
    border-top-right-radius: .5em;
}

.UC-L-Name {
    margin: 0;
    font-size: 300%;
}

.UC-L-JD {
    margin: 0;
    font-size: 150%;
}

.UC-L-Top > button {
    background-color: transparent;
    color: var(--URBAN-WHITE);
    border: none;
}
.UC-L-Top > button:hover {
    color: var(--URBAN-HOVER-WHITE);
    cursor: pointer;
}

/* --- */

.UC-L-Bot {
    background-color: var(--URBAN-WHITE);
    border-radius: 0 0 1em 1em;
    display: flex;
    justify-content: space-evenly;
    box-shadow: 0px 0px 3px rgba(0,0,0,.3);
}

.UC-Stat {
    margin: min(2%, 10px) 0;
}

.UC-Stat > * {
    margin: 0;
    text-align: center;
}

.UC-Stat h1 {
    color: var(--URBAN-SKYBLUE);
    font-size: 3em;
    position: relative;
    font-family: Arial, Helvetica, sans-serif;
}

.UC-Stat h1 > span {
    position: absolute;
    font-size: 60%;
    bottom: 3px;
}