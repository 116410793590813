.LinkTray {
    display: grid;
    grid-template-columns: 2.5em repeat(5, 1fr);
    padding: .4em;
}

.ExitTrayBtn {
    display: flex;
    /* width: auto; */
    height: 100%;
    background-color: rgba(255,255,255,0);
    border: none;
    border-right: 2px solid gray;
    border-radius: .5em 0 0 .5em;
    color: white;
    transition: background-color .05s;
}

.ExitTrayBtn:hover, .ExitTrayBtn:active {
    cursor: pointer;
    background-color: rgba(255,255,255,15%);
}

.ExitTrayBtn > svg {
    transform: scaleY(1.75);
    display: inline-block;
    width: 80%;
    height: 100%;
    fill: white;
}

.ExitTrayBtn > svg > path {
    stroke: white;
    stroke-width: 1.25;
}

.LinkButtons {
    display: grid;
    grid-row: 1fr 1fr;
    row-gap: .5em;
    grid-column: 6;
    padding-left: 1em;
}

.LinkBody {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: 1fr 1fr;

    grid-column: 2/6;

    row-gap: .5em;
    column-gap: .25em;

    padding-left: .5em;
    /* padding-right: .5em; */
}

.LinkBody #LinkTray-text  {grid-row: 1; grid-column: 1/ span 3;}
.LinkBody #LinkTray-title {grid-row: 1; grid-column: 4/ span 3;}

.LinkBody #LinkTray-urlpair {grid-row: 2; grid-column: 1/ span 6;}


.LinkBody input {
    height: 2em;
    outline: transparent 3px solid;
    border: none;
    border-radius: .25em;
    transition: outline .1s;
}

.LinkBody input:focus-visible {
    outline-color: var(--URBAN-FOCUS-BLUE);
}

.LinkBody input::placeholder {font-style: italic;}

.URL-Pair {
    display: flex;
    align-items: center;
    background-color: white;
    border-radius: .25em;
    overflow: hidden;
    outline: transparent solid 3px;
    transition: all .1s;
}

.URL-Pair > input {
    outline: none;
    border: none;
    box-sizing: border-box;
    flex-grow: 1;
}

.URL-Pair > select {
    box-sizing: border-box;
    appearance: none;
    text-align: right;
    padding: .25em;
    border: none;
    background-color: hsl(0,0%,75%);
    color: hsl(0,0%,20%);
    height: 100%;
    
    outline: none;
    border: none;

    transition: all .1s;
}


.URL-Pair:focus-within {
    outline: var(--URBAN-FOCUS-BLUE) solid 3px;
}

.URL-Pair:focus-within > select:not(:focus-visible) {
    background-color: var(--URBAN-FOCUS-BLUE);
    color: white;
}

@media screen and (hover:hover) {
    .URL-Pair > select:hover {
        background-color: hsl(0,0%,60%);
        cursor: pointer;
    }
    
    .URL-Pair:focus-within > select:hover {
        background-color: hsl(203,74%,45%);
    }
}


.URL-Pair:focus-within > select:focus-visible {
    background-color: hsl(203, 74%, 90%);
    color: black;
}

.LinkButtons button {
    border-radius: .25em;
    border: none;
}

.LinkButtons #LinkTray-delink {
    background-color: transparent;
    color: white;
    letter-spacing: 1px;
}

.LinkButtons #LinkTray-apply {
    background-color: hsl(216, 70%, 46%);
    color: white;
    letter-spacing: 1px;
}

.LinkButtons #LinkTray-apply:disabled {
    background-color: hsl(0, 0%, 25%);
    color: hsl(0,0%,50%);
}

@media screen and (hover:hover) {

    .LinkButtons #LinkTray-delink:hover {
        cursor: pointer;
        background-color: hsl(0,0%,30%);
    }
    .LinkButtons #LinkTray-apply:not(:disabled):hover {
        cursor: pointer;
        background-color: var(--URBAN-FOCUS-BLUE);
    }
}

@media screen and (max-width: 700px) {
    .LinkBody {
        grid-template-columns: 1fr;
        grid-template-rows: repeat(3,1fr);
    }

    .LinkBody #LinkTray-text {
        grid-row: 1;
        grid-column: 1;
    }
    .LinkBody #LinkTray-title {
        grid-row: 2;
        grid-column: 1;
    }
    .LinkBody #LinkTray-urlpair {
        grid-row: 3;
        grid-column: 1;
    }
}