.Hdr-Inc, .Hdr-Dec {
    background-color: transparent;
    border: none;
    font-size: 1.5em;
    font-weight: 400;
    color: var(--URBAN-GRAY);
    background-color: var(--URBAN-SOFT-GRAY);
    position: relative;
}

.Hdr-Inc:hover, .Hdr-Dec:hover {
    cursor: pointer;
    background-color: var(--URBAN-LIGHT-GRAY);
}