.vN01-NODE {
    padding-left: 0;
    padding-right: 2px;
    padding-top: 0px;
    padding-bottom: 0;
    display: grid;
    grid-template-columns: 2em auto;
    border: 0;
}

.vN01-SIDELABEL {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-width: 2em;

    background-color: var(--node-bckgnd-color);
    color: white;
    border-radius: 12px 0 0 12px;
    z-index: 1;
}

.vN01-SIDELABEL > .vN01-LABEL {
    flex: 1;
    margin: 5px 0;
    display: flex;
    flex-direction: inherit;
    justify-content: center;
}
.vN01-SIDELABEL > .vN01-LABEL > div {
    position: sticky;
    text-align: center;
    
    top: 0;
    bottom: 10px;
}

.vN01-SIDELABEL > button:active {
    background-color: aliceblue;
    color: black;
    cursor: pointer;
}


@media only screen and (hover: hover) {
    .vN01-SIDELABEL > .vN01-LABEL .Anchor label:hover {
        animation: 2s ease-in-out 1s infinite alternate anim-sleep;
    }
    .vN01-SIDELABEL > button:hover {
        background-color: aliceblue;
        color: black;
        cursor: pointer;
    }   
}

@keyframes anim-sleep {
    from {
        /* text-shadow: none; */
    }
    to {
        text-shadow: 0px 0 15px #FFF, 
                     0px 0 10px #FFF, 
                     0px 0 5px #FFF; 
        color: #DDD;
    }
}

.vN01-SIDELABEL > button {
    border: 0;
    background-color: var(--node-bckgnd-color);
    color: white;
}

.vN01-SIDELABEL > button:first-child {
    padding-top: 5px;
    border-radius: 10px 0 0 0;
}

.vN01-SIDELABEL > button:last-child {
    padding-bottom: 5px;
    border-radius: 0 0 0 10px;
}

.vN01-NoBODY {
    position: relative;
    background-color: rgb(209, 210, 211);
    border: 1px solid black;
    /* border-top: 0; */
    border-radius: 0 5px 5px 0;
    z-index: 0;
}

/* == Change Type === */

.CT-CMenu-Scroll {
    max-height: 250px;
    overflow-y: scroll; 
}

.CT-CMenu-Scroll::-webkit-scrollbar {
    display: none;
}

@media screen and (max-width: 800px) {
    .vN01-NODE {
        grid-template-columns: 2.2em auto;
    }
    /* .vN01-SIDELABEL {
        font-size: medium;
    } */
    .vN01-SIDELABEL > .vN01-LABEL > div {
        bottom: 3.5em;
    }
}