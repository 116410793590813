.NavBtn {
    aspect-ratio: 1;
    width: 100%;
    /* background-color: white; */
    background-color: transparent;
    text-align: center;
    cursor: pointer;
    transition: all .1s ease-out;
    border: none;
    padding: 15%;
    box-sizing: border-box;
    /* position: relative; */

    color: white;
    stroke: white;
    fill: white;
}

.NavBtn.adminBtn {
    border-left: 5px solid yellow;
    /* stroke: yellow; */
    /* fill: yellow; */
    /* color: yellow; */
}

.NavBtn-User {
    background-color: var(--URBAN-GRAY);
    background-position: center;
    background-size: 100%;
}


/* Icon */
.NB-Icon {
    width: 100%;
    position: relative;
    display: flex;
    justify-content: center;
}

.NB-Icon svg {
    width: inherit;
    height: inherit;
}

/* Icon Title */
.NB-IconTitle {
    /* width: 100%; */
    position: absolute;
    top: 110%;
    /* left: 0; */
    text-align: center;
    color: transparent;
    font-size: smaller;
    font-weight: 500;
    font-family: Arial, Helvetica, sans-serif;

    white-space: nowrap;
}

.NB-IconTitle.visibleTitle {
    color: inherit;
}

@media screen and (hover:hover) {
    .NavBtn:hover {
        background-color: rgb(38, 142, 247);
        /* background-color: white;
        fill: black;
        stroke: black;
        color: black; */
    }
    .NavBtn:hover .NB-IconTitle:not(.visibleTitle) {
        color: inherit;
    }
    .NavBtn-User:hover {
        background-size: 110%;
    }
}