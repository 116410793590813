.Menu-Wrapper {
    position: relative;
}

.CMenu {
    position: absolute;
    color: black;
    padding: 5px;
    background-color: var(--URBAN-WHITE);
    box-shadow: 0px 0px 5px 2px rgba(0,0,0,.5);

    border-radius: 5px 5px 5px 5px;
    max-height: 250px;
    /* width: 120px; */
    white-space: nowrap;
}

.Anchor {
    z-index: 1;
    width: 100%;
}

.Anchor-Opt, ._CItem {
    height: 1.5em;
    text-align: left;
    /* color: black; */
    border-bottom: 1px solid var(--URBAN-CLOUD);
}
.Anchor-Opt:hover, ._CItem:hover {
    background-color: var(--URBAN-CLOUD);
    cursor: pointer;
}
.Anchor-SubMenu{
    padding-right: 20px;
}
.Anchor-SubMenu:after{
    content: '>';
    position: absolute;
    right: 0;
    font-weight: 700;
}

.Anchor-Opt-Del {
    border-left: 5px solid var(--URBAN-RED);
    border-bottom: none;
}

.Anchor > * {
    cursor: pointer;
}

.CMe-L { right: 100%; }
.CMe-R { left: 100%; }
.CMe-T { top: 0; }
.CMe-B {  bottom: 0;}

.CMe-T.CMe-L { border-top-right-radius: 0; }
.CMe-T.CMe-R { border-top-left-radius: 0; }
.CMe-B.CMe-L { border-bottom-right-radius: 0; }
.CMe-B.CMe-R { border-bottom-left-radius: 0; }

._CItem > button {
    background-color: transparent;
    border: none;
    width: 100%;
    height: 100%;
}
._CItem > button:not(:disabled){
    cursor: pointer;
}