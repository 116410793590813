#Login {
    grid-template-columns: 50%;
}

#Login .AA-Container {
    height: 500px;
    background-image: url(imgs/corgi.png);
    background-repeat: no-repeat;
    background-position-y: bottom;
    background-position-x: right;
    background-size: 50%;
}

#Login .AA-Btn-Wrapper, 
#Login input[type=text],
#Login input[type=password],
#Login .AA-C-Errs {
    width: 50%;
}

@media (max-width: 1000px){
    #Login input[type=text],
    #Login input[type=password], 
    #Login .AA-Btn-Wrapper,
    #Login .AA-C-Errs {
        width: 100%;
    }
}

@media (max-width: 800px) {
    #Login .AA-Container {
        min-height: 100%;
    }

    #Login .AA-Header > *, #Login .AA-Content {
        margin-left: 5%;
        margin-right: 5%;
    }
}