nav {
    width: 3.5em;
    height: 100%;
    color: white;
    background-color: var(--URBAN-BLUE);
    /* position: relative; */

    /* display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: space-between; */
    z-index: 9999;
}

.Reg-Nav {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: space-between;
}

.Comp-Nav{
    display: none;
    /* background-color: cadetblue; */
}

.CNav-Name, .CNav-Ham {
    font-size: 150%;
    line-height: 100%;
    text-align: center;
}
.CNav-Name {
    display: flex;
    font-weight: 600;
    padding-left: .5rem;
    padding-right: .5rem;

    color: inherit;
    text-decoration: none;
    height: 100%;
    align-items: center;
}

.CNav-Ham {
    font-weight: 700;
    padding-right: .25rem;
    height: 100%;
    aspect-ratio: 1.5;
    border: none;
    background-color: transparent;
    color: inherit;
    transition: all .05s ease-out;
}
.CNav-Ham > span {
    color: inherit;
    display: inline-block;
    transform: scaleX(1.5);
}
.CNav-Ham:active {background-color: #ffffff1a;}
.CNav-Ham > .reduceTransform {
    transform: scaleX(1);
}
.CNav-Ham:has(.reduceTransform) {
    /* background-color: var(--URBAN-DARK-BLUE) */
    background-color: var(--URBAN-WHITE);
    color: black;
}

@media screen and (max-width: 800px) {
    nav {
        height: 100%;
        width: 100%;
        /* overflow: hidden; */
    }

    .Reg-Nav {
        display: none;
    }

    .Comp-Nav {
        height: 100%;
        box-sizing: border-box;
        display: flex;

        justify-content: space-between;
        align-items: center;
    }
}

.CNav-Blocker {
    position: absolute;
    width: 100%;
    height: calc(100% - 3em);
    left: 100%;
    top: 3em;
    background-color: transparent;
    transition: background-color .4s ease-out;
}

.CNav-HamMenu {
    position: absolute;
    width: min(60%, 400px);
    top: 3em;
    right: -100%;

    /* background-color: var(--URBAN-DARK-BLUE); */
    background-color: var(--URBAN-WHITE);
    color: black;
    /* max-height: 1000px; */
    height: calc(100% - 3em);
    overflow-y: scroll;

    display: flex;
    flex-direction: column;
    transition: right .4s ease-out;
}

.CNav-HamBlocker-Out {
    left: 0;
    background-color: rgba(0,0,0,.3);
}
.CNav-HamMenu-Out {
    right: 0;
}

/* === Impromptu Styling === */

.CNav-HamMenu-Opt {
    padding-left: 1rem;
    height: 3rem;
    font-size: 125%;
    display: flex;
    align-items: center;
    color: var(--URBAN-CODE-GRAY);
}

.CNav-HamMenu-Opt svg {
    height: inherit;
    /* aspect-ratio: 1; */
    padding: .75em .5em .75em 0em;
    box-sizing: border-box;
    fill: currentColor;
    stroke: currentColor;
}

.CNav-HamMenu-Opt:first-child {
    margin-top: .2rem;
}
.CNav-HamMenu-Opt:last-child {
    margin-bottom: .2rem;
}

.CNav-HamMenu-Opt:not(:last-child) {
    border-bottom: 3px solid rgba(0,0,0,.05);
}

.CNav-HamMenu-Opt-UserAvt {
    height: 50%;
    background-color: var(--URBAN-CODE-GRAY);
    box-sizing: border-box;
    background-size: contain;
    padding: .75em 0em .75em 0em;
    margin-right: .3em;
    margin-left: -.3em;
    aspect-ratio: 1;
}

/* === alpha BADGE === */

.alpha-badge {
    color: var(--URBAN-BLUE);
    background-color: white;
    padding: .025em .4em;
    border-radius: .1em;
    font-size: .75em;
}

.alpha-div {
    margin: .25em;
    text-align: center;
    font-weight: bold;
}

.alpha-badge.alpha-div {
    padding: .025em 0em;
    font-size: 1em;
}


@media screen and (max-width: 350px) {
  .alpha-badge span {
    display: none;
  }  
  .alpha-badge {
    padding: .025em .25em;
  }
  .alpha-badge::after {
      content: "\03b1";
      font-style: italic;
      width: .5ch;
      height: .5ch;
  }
}
