.UserDisplay {
    width: 100%;
    aspect-ratio: 7;

    background-color: var(--URBAN-GRAY);
    display: flex;
    position: relative;
}

.UD-PFP {
    height: 100%;
    aspect-ratio: 1;
    background-color: burlywood;
    background-size: cover;
}

.UD-Info {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
    color: var(--URBAN-WHITE);
    /* padding: 0rem 1rem min(1%,.5rem) 1rem; */
    padding: .2em .5em min(3%, .75em) 1em;
}

.UD-Name {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.UD-Name > *{
    margin: 0;
    font-size: min(8vw, 3rem);
    font-weight: 600;
}

.UD-JoinDate > *{
    margin: 0;
    font-size: min(3vw, 1.5rem);
    font-weight: 500;
}

.UD-Options {
    color: var(--URBAN-WHITE);
    font-size: x-large;
    height: 1.5em;
    aspect-ratio: 1;
    border-radius: 100%;
    border: 0;
}

.UD-Options.FullScreen {
    background-color: rgba(0,0,0,.4);
}

.UD-Options.FullScreen:hover {
    cursor: pointer;
    background-color: rgba(0,0,0,.1);
}