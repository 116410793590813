pre {
    padding: 2% 2%;
    background-color: var(--URBAN-GLOOM);
    border-radius: .5em;
    color: var(--URBAN-WHITE);
    overflow-x: scroll;
    /* justify-self: center; */
    max-width: 900px;
}

pre::-webkit-scrollbar{
    display: none;
}

pre code:before {
    content: '';
}

pre code {
    padding: 0;
    margin: 0;
}
