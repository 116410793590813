.CropModal {
    padding: 0;
    width: min(100%, 700px);
    border-color: rgba(0,0,0,.8);
}

.CropModal::backdrop {background-color: rgba(0,0,0,.5);}

.CropModal-Content-Container {
    display: contents;
}

.CropSection {
    background-color: rgba(0,0,0,.9);

    height: 500px;
    max-height: 500px;

    display: flex;
    justify-content: center;
    align-items: center;
}

.CropSection-ReactCrop {
    max-height: inherit;
}

/* .CropModal .CropSection img {
    width: 100%;
    height: inherit;
    object-fit: contain;
} */

.CropModal .OptionsBar {
    display: flex;
    justify-content: space-between;
    padding: .5em;
    height: 2.5em;
    background-color: var(--URBAN-GRAY);
}

.CropModal .OptionsBar > button {
    height: inherit;
    aspect-ratio: 1;
}

@media screen and (max-width: 800px) {
    .CropModal {
        width: 100%;
        height: 100dvh;
        max-width: 100%;
        max-height: 100vh;
        margin: 0;
        border: 0;
    }

    .CropModal-Content-Container {
        display: flex;
        flex-direction: column;
        height: 100%;
    }

    .CropSection {    
        height: 83lvh;
        max-height: 83lvh;
    }

    .CropModal .OptionsBar {
        flex: 1;
        padding-left:  .75em;
        padding-right: .75em;
    }
}