.delete-btn {
    border: 0;
    background-color: var(--URBAN-RED);
    color: rgb(241, 241, 241);
    font-weight: 700;
    letter-spacing: 2px;
}

.delete-btn:hover {
    background-color: var(--URBAN-HOVER-RED);
    cursor: pointer;
}

.LoadMore-btn {
    margin: 2em 12.5%;
    width: 75%;
    height: 2.5em;
    border: 3px solid var(--URBAN-LIGHT-GRAY);
    border-radius: .5em;

    color: var(--URBAN-LIGHT-GRAY);
    font-weight: 600;
    font-size:medium;

    letter-spacing: 5px;
    transition: all .1s ease-out;
}

.LoadMore-btn:hover,
.LoadMore-btn:focus {
    cursor: pointer;
    color: var(--URBAN-HOVER-WHITE);
    border-color: var(--URBAN-HOVER-WHITE);
}