.doc-Banner-Placeholder {
    /* For Large Screens */
        /* aspect-ratio: 2.5; */
    /* For Small Screens */
        /* aspect-ratio: 2; */
    background-color: #464646;
}

.doc-Banner img,
.doc-Banner-Placeholder {
    width: 100%;
    aspect-ratio: 2.5;
    border-radius: .5em;

    object-fit: cover;
}

@container (max-width: 850px) {
    .doc-Banner img,
    .doc-Banner-Placeholder {
        border-radius: 0;
    }
}

@container (max-width: 500px) {
    .doc-Banner img,
    .doc-Banner-Placeholder {
        aspect-ratio: 2;
    }
}