.IU-Dialog.Banner-Dialog {
    width: min(35rem, 90%);
    overflow: visible;
}

.Banner-Dialog .Subsection {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: .5em;
    margin: 1em 0;
}

.Banner-Dialog .Subsection p {
    color: white;
    margin: 0;
}

.NMIS_2 label {
    background-size: cover;
    background-position: center;
}

.SubImage-Button {
    background-size: cover;
    background-position: center;
    border-radius: .5em;
    color: transparent;
    border: none;
}

.SubImage-Button span {
    background-color: transparent;
}

@media screen and (hover:hover) {
    .SubImage-Button:hover {
        cursor: pointer;
    }
    .SubImage-Button:hover span,
    .SubImage-Button:focus-visible span {
        padding: .5em;
        background-color: rgba(0,0,0,.8);
        border: 3px solid var(--URBAN-FOCUS-BLUE);
        border-radius: .25em;
        color: var(--URBAN-FOCUS-BLUE);
    }
}

.Main-Banner-Selector-Wrapper {
    background-color: rgba(0,0,0,.35);
    border-radius: .6em;
    padding: .25em;
}