:root {
    --IB-B-width: 4px;
    --IB-Color: white;
    --IB-Hover-Color: lightblue;
}

.IB-Container {
    position: relative;
    width: 100%;
    height: 10em;

    background-color: #252525;

    background-repeat: no-repeat;
    background-position: center;
    background-size: cover, contain;
}

.IB-Preview {
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,.6);

    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;

    padding: 1.5em;
}

.ImgButton {
    width: 100%;
    height: 100%;

    border: 5px solid;
    border-radius: 10px;
    transition: all .3s ease-out;
    font-weight: 700;
}

.ImgButton.IB-Empty {
    background-color: rgba(0,0,0,.8);
    color: var(--URBAN-WHITE);
    border-color: var(--URBAN-WHITE);
}

.ImgButton.IB-Filled {
    background-color: transparent;
    color: transparent;
    border-color: transparent;
}

.ImgButton:hover,
.ImgButton:active {
    cursor: pointer;
    transform: scale(1.03);

    color: var(--URBAN-HOVER-BLUE);
    border-color: var(--URBAN-HOVER-BLUE);
    outline-color: var(--URBAN-HOVER-BLUE);
    background-color: rgba(0,0,0,.8);
}

@media only screen and (max-width: 800px) {
    .ImgButton.IB-Filled:hover {
        transform: none;
        background-color: transparent;
        color: transparent;
        border-color: transparent;
        outline: none;
    }
}