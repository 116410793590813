.SmallUserCard-Wrapper {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
    column-gap: .5em;
    row-gap: .5em;
    color: var(--URBAN-GRAY);
}

.SmallCard-Container {
    height: 3em;
}