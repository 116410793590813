.LoadingDiv {
    /* background-color: var(--URBAN-HOVER-WHITE); */
    background-image: var(--URBAN-LIGHT-GRAY);
        /* linear-gradient(
            110deg,
            var(--URBAN-LIGHT-GRAY) 0 40%, 
            45%,
            #cdcdce 50% 55%, 
            60%,
            var(--URBAN-LIGHT-GRAY) 65%
        ); */
    background-size: 400%;
    width: inherit;
    min-height: 300px;
    border-radius: 7px;

    /* animation: loadingDiv 2s 0s infinite; */
    animation: sleepingLoadingDiv 4s 0s ease-in infinite alternate;
}

.LoadingCircle {
    /* width: 100%; */
    height: inherit;
    max-width: 5em;
    aspect-ratio: 1;
    border-radius: 50%;
    border: .5em solid #f3f3f3;
    border-top-color: var(--URBAN-BLUE);
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }    
}

@keyframes sleepingLoadingDiv {
    from {background-color: var(--URBAN-LIGHT-GRAY)}
    30% {background-color: var(--URBAN-LIGHT-GRAY)}
    50% { background-color: #bbb}
    70% {background-color: var(--URBAN-LIGHT-GRAY)}
    to {background-color: var(--URBAN-LIGHT-GRAY)}
}

@keyframes loadingDiv {
    0% { background-position: 100% 0%; }
    100% { background-position: 0% 0%;}
}