.__SVGButton {
    background-color: black;
    fill: white;

    display: inline-flex;
    justify-content: center;
    align-items: center;

    border-style: solid;
    
    transition: all .1s linear;
    position: relative;
}

.RoundSVGButton {
    border-radius: 50%;
    width: inherit;
    aspect-ratio: 1;
}

.CropSVG {width: 100%;} 
.CancelSVG {width: 80%;}
.CheckmarkSVG {width: 130%;}

.__SVGButton:disabled {opacity: .6;}

@media screen and (hover:hover) {
    .__SVGButton:hover:not(:disabled) {cursor: pointer;}
    
    .__SVGButton:hover:not(:disabled):has(.CropSVG)   {fill: var(--URBAN-FOCUS-BLUE);}
    .__SVGButton:hover:not(:disabled):has(.CancelSVG) {fill: var(--URBAN-RED);}

    .__SVGButton.title:hover::before  {
        display: block;
        animation: .2s linear 2s forwards titleReveal;
    }
}

.__SVGButton:active:has(.CropSVG)      {fill: var(--URBAN-FOCUS-BLUE);}
.__SVGButton:active:has(.CancelSVG)    {fill: var(--URBAN-RED);}
.__SVGButton:active:has(.CheckmarkSVG) {fill: var(--URBAN-FOCUS-BLUE);}

.__SVGButton.title::before {
    content: attr(data-hovertitle);
    display: none;
    position: absolute;
    text-align: center;
    width: max-content;
    bottom: 120%;
    padding: .3em .5em;
    border-radius: .5em;

    opacity: 0;

    color: var(--URBAN-WHITE);
    background-color: var(--URBAN-GRAY);
    border: 2px solid var(--URBAN-BLACK);
}

@keyframes titleReveal {
    from { opacity: 0;}
    to {   opacity: 1;}
}