.Settings-SubRoute {
    color: white;
}

.SSR-Header > h1 {
    display: flex;
    justify-content: space-between;

    font-size: x-large;
}

.SSR-Container {
    position: relative;
    width: min(100%, 40em);
}

.SSR-Section > div {
    padding-left: 1em;
    color: rgb(212, 212, 212);
}

.SSR-Invalid {
    color: rgb(250, 83, 83);
    font-weight: 600;
}

@media screen and (max-width: 600px) {
    .SSR-Section > div {
        padding-left: .5em;
    }
}

.SSR-Confirm-Button {

    padding: 0 1em;
    background-color: var(--URBAN-SKYBLUE);
    color: var(--URBAN-WHITE);

    border: 0;
    border-radius: .25em;

    font-size: 16px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: 600;
}
.SSR-Confirm-Button:not(:disabled):hover {
    cursor: pointer;
    background-color: var(--URBAN-FOCUS-BLUE);
}

.SSR-Confirm-Button:disabled {
    opacity: .5;
    background-color: var(--URBAN-LIGHT-GRAY);
}