.FllwTemplate {
    margin-top: 1rem;
    background-color: var(--URBAN-WHITE);
    border-radius: 5px;
    box-shadow: 1px 1px 5px rgba(0,0,0,.3);
    border: 1px solid rgba(0,0,0,.1);
}

.FllwTemplate-Header {
    padding: .5rem;
    display: flex;
    justify-content: space-between;
}

.FllwTemplate-Header > h2 {
    color: var(--URBAN-GRAY);
    margin: 0;
}

.FllwTemplate-Body {
    border-top: 3px solid rgba(0,0,0,.15);
    padding: .5rem;
}

.FllwTemplate-Body-Empty {
    margin: 0;
    font-style: italic;
    opacity: .5;
}

.FllwTemplate-User {
    height: 2rem;
    width: 100%;
    margin: .2rem 0;
    position: relative;
    color: var(--URBAN-GRAY)
}


.FllwTemplate-User-Placeholder {
    background-color: var(--URBAN-LIGHT-GRAY);
    animation: sleepingLoadingDiv 4s 0s infinite;
}

.Fllw-Bttn {
    font-weight: 600;
    font-size:medium;
    padding-left: 1rem;
    padding-right: 1rem;
    border-radius: .25rem;

    transition: all .1s ease-out;
}

.Follow-Button {
    background-color: var(--URBAN-SKYBLUE);
    border: 0;
    color: var(--URBAN-WHITE);
}
.Follow-Button:disabled {background-color: var(--URBAN-DISABLED-BLUE);}

.Unfllw-Button {
    background-color: transparent;
    color: var(--URBAN-GRAY);
    border: 2px solid var(--URBAN-GRAY);
}
.Unfllw-Button:disabled {border-color: rgba(0,0,0,.3); color: rgba(0,0,0,.3);}

.Follow-Button:focus-visible, 
.Follow-Button:active 
{background-color: var(--URBAN-FOCUS-BLUE);}
.Unfllw-Button:focus-visible, 
.Unfllw-Button:active 
{border-color: rgba(0,0,0,.5); color: rgba(0,0,0,.5);}

@media screen and (hover: hover) {
    .Fllw-Bttn:hover {cursor: pointer;}
    .Follow-Button:hover {background-color: var(--URBAN-FOCUS-BLUE);}
    .Unfllw-Button:hover {border-color: rgba(0,0,0,.5); color: rgba(0,0,0,.5);}
}