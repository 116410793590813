.img_node_container {
    text-align: center;
}

.img_node_container > img {
    min-width: 50%;
    max-width: 80%;
    border-radius: 5px;
}

.img_node_container > p {
    font-size: small;
    margin-top: .3em;
    color: rgb(122, 122, 122);
}

@media (max-width: 800px) {
    .img_node_container > img {
        max-width: 100%;
    }
}