.UserAbout {
    padding: .75em;
    background-color: var(--URBAN-GRAY);
    color: var(--URBAN-WHITE);
    border-radius: .5rem;
}

.UserAbout > *:first-child {
    /* margin: 0 0 1em 0; */
    margin: 0;
}

.UserAbout > p {
    font-size: 1.03rem;
}

.UA-EmptyBio {
    /* text-align: center; */
    color: rgba(255,255,255,.5);
}

.UA-EmptyBio.light {
    color: black;
}