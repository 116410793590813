#Collection {
    --DC-Height: 18em;
    --DC-Margin: 0;
}

#Collection header {
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: var(--URBAN-GRAY);
    margin-bottom: 1em;
    padding: .25em 10px;
    height: 3.5em;
}

#Collection header h1 {
    color: var(--URBAN-WHITE);
    margin: 0;
}

.Collection-Drafts {
    margin: 0 15px;
    display:  grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    column-gap: 1em;
    row-gap: 1em;
}

.Collection-Grid-EmptyCell {
    height: var(--DC-Height);
    background-color: var(--URBAN-HOVER-WHITE);
    opacity: .5;
    border-radius: 5%;

    text-align: center;
    position: relative;
}

.Collection-Grid-EmptyCell span {
    position: absolute;
    bottom: -12%;
    right: -2%;
    font-family:Arial, Helvetica, sans-serif;
    font-size: 6em;
    /* font-style: italic; */
    font-weight: 900;
    color: var(--URBAN-SOFT-GRAY);
    user-select: none;
}

.NewDraft-Btn {
    color: var(--URBAN-CLOUD);
    font-size: 125%;
    font-weight: 700;
    width: 12em;
    height: 2.5em;

    border-color: var(--URBAN-CLOUD);
    border: 5px solid;
    border-radius: 2em .4em .4em 2em;
    background-color: transparent;
    display: flex;
    justify-content: space-between;
    align-items: center;
    transition: all .2s ease-in-out;
}

.NewDraft-Btn:disabled {
    color: var(--URBAN-DISABLED-GRAY);
}

.NewDraft-Btn:hover:not(:disabled) {
    cursor: pointer;
    color: var(--URBAN-HOVER-BLUE)
}

.NewDraft-Btn span:first-child {
    display: inline-block;
    width: 1em;
    height: 1em;
    border: 5px solid;
    border-radius: 50%;
    font-weight: 1000;
    margin-right: 10px;
    line-height: 100%;
    text-align: center;
}

.NewDraft-Btn p {
    margin: 0;
}

@media (max-width: 800px) {
    #Collection header {
        height: auto;
        /* flex-direction: column; */
        /* justify-content: stretch; */
    }
}
/* 
@media (max-width: 500px) {
    .NewDraft-Btn span:not(:first-child) {
        display: none;
    }
} */