.Small-User-Card {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-content: center;
    text-decoration: none;
    color: inherit;
}

.Small-User-Card:hover {
    background-color: rgba(0,0,0,.08);
}

.Small-User-Card > * {
    display: flex;
    align-items: center;
}

.Small-User-Card-Left > span {
    margin-left: .5rem;
    font-weight: 600;
}

.Small-User-Card-Img {
    height: 100%;
    border-radius: 10%;
}