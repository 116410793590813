.DraftCard {
    background-color: var(--URBAN-WHITE);
    height: var(--DC-Height);
    margin: var(--DC-Margin);
    box-shadow: 4px 3px 8px 1px rgba(0,0,0,.2);
    border-radius: 10px;
    position: relative;
}

.DraftCard:hover{
    cursor: pointer;
    outline: 3px solid dodgerblue;
}

.DraftCard > .DC-Banner{
    width: 100%;
    height: 8em;
    background-color: var(--URBAN-BLUE);
    border-radius: 10px 10px 0 0;
}

.DraftCard > .DC-Info {
    margin: 2%;
}

.DC-Info h3 {
    margin-bottom: 0;
}

/* ===== */

.DC-Options {
    right: 10px;
    bottom: 10px;
    position: absolute;
}

.DC-Opt-Anchor {
    font-size: 2em;
    font-weight: 500;
    text-align: center;
    margin-left: 5px;
}

.DC-Opt-Anchor:hover {
    color: var(--URBAN-LIGHT-GRAY);
}

.DC-Opt-delete-btn {
    margin: 5px 5px 5px 8px;
    padding-left: 0;
    background-color: transparent;
    border: none;
    color: var(--URBAN-RED);
    font-weight: 700;
}

.DC-Opt-delete-btn:hover,
.DC-Opt-delete-btn:focus {
    background-color: var(--URBAN-CLOUD);
    color: var(--URBAN-HOVER-RED);
    cursor: pointer;
}

.DC-Opt-delete-btn span {
    background-color: var(--URBAN-RED);
    font-size: 1.25em;
    color: white;
    padding: 1px 5px;
    margin-right: 5px;
}