summary {
    background-color: var(--URBAN-BLUE);
    color: white;
    padding: .75em;
    padding-left: .5em;
    margin: .25em 0;
}

@media screen and (hover:hover) {
    summary:hover {
        cursor: pointer;
    }
}

.details-body {
    margin: 1em 0;
    padding-left: 1em;
    border-left: 3px solid rgba(0,0,0,.15);
}