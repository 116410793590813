.DocList {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    /* justify-content: center; */
    justify-items: center;
    row-gap: 1em;
    column-gap: 1em;
}

.DocList:has(.DL-Empty){
    grid-template-columns: auto;
    justify-content: center;
}

.DL-Empty {
    margin-top: 5em;
    text-align: center;
    font-style: italic;
    font-weight: 300;
}